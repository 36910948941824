html {
  background-color: #FFF7FC;
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30%;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
  height: 30%;
}
