.container-signup {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 75%;
  width: 65%;
  border-right: 2px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 10px;
  min-height: 75%;
}

.container-flag {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.left-part {
  height: 100%;
  background-color: #ff00a0bd;
  width: 50%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.right-part {
  height: 100%;
  background-color: white;
  width: 50%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.container-signin {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-right: 40px;
  padding-left: 40px;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
}

.audience-logo {
  width: 100px;
}

.dashboard-screenshot {
  height: auto;
  border-radius: 5px;
  margin-bottom: 60px;
  width: 85%;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.container-screenshot {
  padding-left: 50px;
  padding-right: 50px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.screenshot-title {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
}

.screenshot-description {
  font-weight: 400;
  text-align: center;
  line-height: 1.35pc;
}

.form-title {
  font-weight: 600;
  margin-top: 30px;
  font-size: 25px;
  margin-bottom: 15px;
}

.form-desc {
  font-size: 15px;
  margin-bottom: 25px;
  color: #888888;
}

.ant-form-item-label {
  text-align: start !important;
}

.submitButton {
  margin-top: 20px;
  height: 40px;
  font-size: 18px;
  width: 60%;
}

.password-strength {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  margin-bottom: 20px;
}

.password-strength > div:first-child {
  width: 66%;
}

.autocomplete-address {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
}

.autocomplete-address:focus {
  outline: transparent !important;
  border-color: #ff29a9 !important;
}

.datePicker {
  width: 100%;
}

.checkbox-cgu,
.checkbox-cgu > div {
  width: 100%;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.container-submit-btn .ant-form-item-control-input-content {
  text-align: center;
}

.signup-success {
  background-color: white;
}

.container-signup-success {
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.container-signup-success > div:first-child {
  font-size: 2em;
  font-weight: 700;
  margin-bottom: 25px;
}

@media (min-width: 1700px) {
  .container-signin {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media (min-width: 1900px) {
  .container-signin {
    padding-right: 100px;
    padding-left: 100px;
  }
}

@media (max-width: 1600px) and (max-height: 900px) {
  .container-signup {
    height: 80%;
  }
}

@media (max-width: 1400px) {
  .container-signup {
    height: 83%;
  }
}

@media (max-width: 700px) {
  .left-part {
    display: none;
  }
  .right-part {
    width: 100%;
    border-radius: 10px;
  }

  .container-signup {
    height: 95%;
    width: 95%;
  }
}

@media (max-height: 700px) {
  .container-signup {
    height: 98%;
    width: 95%;
  }
}
