.error-page-container > .ant-spin-nested-loading {
  height: 100%;
}
.error-page-container .ant-spin-container {
  height: 100%;
}
.error-page-container .error-informations {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.error-page-container {
  height: 100vh;
}
